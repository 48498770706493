import React, { useState } from "react"
import { Container, Carousel } from "react-bootstrap"
import Image from "@components/common/image"

const PartnersTestimonial = () => {
  const [index, setIndex] = useState(0)

  const getIndicatorStyle = i => {
    //if active index;
    if (i === index) {
      return {
        //no thing needs to done
      }
    }

    let scale = 1 - 0.2 * Math.abs(i - index)
    let translate = (i < index ? 80 : -20) + Math.abs(i - index) * 100
    let side = i < index ? "-" : ""

    return {
      transform: `translateX(calc(${side}${translate}%)) scale(${scale})`,
    }
  }

  const handleArrowsClick = direction => {
    let i = index
    if (direction === "next") {
      i++
    } else {
      i--
    }

    if (i > 6) {
      i = 0
    }
    if (i < 0) {
      i = 6
    }

    setIndex(i)
  }

  const handleAvatarClick = (e, i) => {
    e.preventDefault()
    setIndex(i)
  }

  return (
    <Container
      data-sal="fade"
      data-sal-delay="100"
      data-sal-easing="ease"
      data-sal-duration="1000"
      id="partners-testimonial"
      className="partners-testimonial"
    >
      <h4>
        We work hand-in-hand with our <span>partners</span> to align interests
        and build winning ventures
      </h4>

      <div className="partners-testimonial-indicator">
        <div style={getIndicatorStyle(-2)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 5)}
          >
            <Image src="pt-tamer-bazzari.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(-1)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 6)}
          >
            <Image src="pt-oussama-gillah.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(0)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 0)}
          >
            <Image src="pt-abdullah-mutawi.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(1)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 1)}
          >
            <Image src="pt-samarth-agrawal.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(2)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 2)}
          >
            <Image src="pt-ahad-bhai.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(3)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 3)}
          >
            <Image src="pt-manhal-nasser.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(4)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 4)}
          >
            <Image src="pt-zaid-shouly.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(5)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 5)}
          >
            <Image src="pt-tamer-bazzari.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(6)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 6)}
          >
            <Image src="pt-oussama-gillah.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(7)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 0)}
          >
            <Image src="pt-abdullah-mutawi.png" />
          </a>
        </div>
        <div style={getIndicatorStyle(8)}>
          <a
            href="#partners-testimonial"
            onClick={e => handleAvatarClick(e, 1)}
          >
            <Image src="pt-samarth-agrawal.png" />
          </a>
        </div>
      </div>
      <Carousel
        activeIndex={index}
        onSelect={i => setIndex(i)}
        nextIcon={<Image src="slide-right.png" alt="" />}
        prevIcon={<Image src="slide-left.png" alt="" />}
      >
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>Dubai Angel Investors</h3>
            <h2>Abdullah Mutawi, Chairman</h2>
            <p>
              “I have had the pleasure of working with Al Falaj Investment on a
              number of venture capital transactions in the tech space. They are
              a young, dynamic and smart team with a dedication to quality and
              professionalism in everything they do. “
            </p>
            <a
              href="https://www.dubaiangelinvestors.me/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>Maxwholesale India</h3>
            <h2>Samarth Agrawal, CEO</h2>
            <p>
              “Al Falaj has been a great partner as our company grows. After
              capital, Al Falaj provides valuable help with its resources,
              access to quality network and connections and strategic advice
              when needed, greatly enhancing the chances and experience of
              building a large organization.”
            </p>
            <a
              href="https://www.maxwholesale.in/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>Bongo</h3>
            <h2>Ahad Bhai, Founder</h2>
            <p>
              “Al Falaj is a young and dynamic family office run by a great
              team. We are very excited to be working together with them, we
              feel there is great synergy and are very excited about our future
              prospects together.”
            </p>
            <a
              href="https://bongobd.com/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>AWJ Investment</h3>
            <h2>Manhal Nasser, CEO</h2>
            <p>
              “We are grateful to have Al Falaj as one of our valuable
              shareholders. They have a well-respected professional team that
              have been very supportive throughout our successful journey. Their
              guidance coupled with their in-depth outlook of the sector and the
              economy have been very useful in shaping our expansion strategy.”
            </p>
            <a
              href="https://awj.ae/en/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>The Captain’s Club</h3>
            <h2>Zaid Al Shouly, CEO</h2>
            <p>
              “Partnering up with Al Falaj Investment firm was a substantial
              strategic milestone for The Captain’s Club. Becoming a member of
              Al Falaj family has been an added value to the business and an
              extended phase of innovation, creativity and success. ”
            </p>
            <a
              href="https://www.thecaptainsclub.ae/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>Genero Capital</h3>
            <h2>Tamer Bazzari, CEO</h2>
            <p>
              “Al Falaj is one of our closest and well respected partners in our
              technology and casual dining investment companies. They have a
              dedicated team that is very responsive and professional to work
              with. We share a common approach in enabling the SME ecosystem
              which Al Falaj delivers on through their strong network and
              valuable commitment”
            </p>
            <a
              href="http://www.genero.ae/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>Razor Capital</h3>
            <h2>Oussama Gillah, Founder</h2>
            <p>
              “Al Falaj has been an extremely transparent, supportive, and
              honest partner throughout the course of our relationship. From the
              start, they have been a pleasure to work with, from sourcing
              mutually beneficial business opportunities to constantly sharing
              ideas.“
            </p>
            <a
              href="https://razorcap.co/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="partner-testimonial-item">
            <h3>Dubai Angel Investors</h3>
            <h2>Abdullah Mutawi, Chairman</h2>
            <p>
              “I have had the pleasure of working with Al Falaj Investment on a
              number of venture capital transactions in the tech space. They are
              a young, dynamic and smart team with a dedication to quality and
              professionalism in everything they do. “
            </p>
            <a
              href="https://www.dubaiangelinvestors.me/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Visit Website
            </a>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="partners-testimonial-control">
        <div className="ptc-progress">
          {index + 1} of 7{" "}
          <span>
            <i style={{ width: ((index + 1) / 7) * 100 + "%" }}></i>
          </span>
        </div>
        <div className="ptc-arrows">
          <Image
            src="slide-left2.png"
            alt=""
            onClick={() => handleArrowsClick("pre")}
          />
          <Image
            src="slide-right2.png"
            alt=""
            onClick={() => handleArrowsClick("next")}
          />
        </div>
      </div>
    </Container>
  )
}

export default PartnersTestimonial
